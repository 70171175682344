<template>
	
	<nav v-if="auth_user && [ 'workspaces', 'workspaces.add', 'workspaces.destroy', 'team', 'team.brand', 'team.members', 'team.invite-member', 'profile', 'api-keys', 'site.add' ].includes( $route.name )" class="w-100 d-flex flex-column justify-content-center">
		
		<router-link 
		class="nav-btn"
		:to="{ name: 'workspaces' }"
		data-bs-toggle="tooltip" data-bs-placement="right" title="Workspaces"
		:class="['workspaces', 'workspaces.add', 'workspaces.destroy'].includes( $route.name ) ? 'router-link-active' : ''"
		>
			<span class="material-icons-outlined text-center">workspaces</span>
		</router-link>

		<router-link 
		class="nav-btn"
		v-if="auth_user.type != 'viewer'"
		:to="{ name: 'team' }"
		:class="['team', 'team.members', 'team.invite-member'].includes( $route.name ) ? 'router-link-active' : ''"
		data-bs-toggle="tooltip" data-bs-placement="right" title="Team"
		>
			<span class="material-icons-outlined text-center">groups</span>
		</router-link>

		<router-link 
		class="nav-btn"
		:to="{ name: 'profile' }"
		data-bs-toggle="tooltip" data-bs-placement="right" title="Profile"
		>
			<span class="material-icons-outlined text-center">person</span>
		</router-link>

		<router-link 
		class="nav-btn"
		:to="{ name: 'api-keys' }"
		data-bs-toggle="tooltip" data-bs-placement="right" title="API keys"
		>
			<span class="material-icons-outlined text-center">vpn_key</span>
		</router-link>

		<button
		class="nav-btn"
		v-if="auth_user.type == 'admin'"
		data-bs-toggle="tooltip" data-bs-placement="right" title="Billing"
		@click.prevent="billing_portal_url"
		>
			<span class="material-icons-outlined text-center">credit_card</span>
		</button>

		<router-link 
		class="nav-btn"
		:to="{ name: 'logout' }"
		data-bs-toggle="tooltip" data-bs-placement="right" title="Logout"
		>
			<span class="material-icons-outlined text-center">lock_open</span>
		</router-link>

	</nav>

</template>

<script>
import { mapGetters } from 'vuex';
import billing_service from '@/services/billing_service'

export default {
	name: 'components.main_nav',

	props: {
		
	},

	data() {
		return {
			billing_loading: false,
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	components: {
		
	},

	methods: {
		async billing_portal_url()
		{
			this.billing_loading = true

			await billing_service.billing_portal_url().then(( response ) => {
				window.location.href = response.data
			}).catch(() => {}).finally(() => {})
		}
	}
}
</script>

<style>

</style>
