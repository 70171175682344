<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

			<site_nav 
				v-if="auth_user && site" 
				:site="site" 
				:is_planning_complete="is_planning_complete"
				:is_design_complete="is_design_complete"
				:is_build_complete="is_build_complete"
				:is_finalise_complete="is_finalise_complete"
				:is_launch_complete="is_launch_complete"
			/>

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<loader v-if="workspace_loading || ( site_loading && !site )" />

			<div class="w-100 h-100 overflow-auto" v-else>

				<router-view 
				v-model="site" 
				:workspace="workspace"
				:is_planning_complete="is_planning_complete"
				:is_design_complete="is_design_complete"
				:is_build_complete="is_build_complete"
				:is_launch_complete="is_launch_complete"
				></router-view>
				
			</div>

			<board_modal v-if="site" v-model="site" />
			
			<share_modal :is_plan="true" v-model="site" />

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import main_nav from '@/components/main_nav'
import board_modal from '@/components/board_modal'
import share_modal from '@/components/share_modal'
import support_link from '@/components/support_link'
import site_nav from '@/components/site_nav'
import workspace_service from '@/services/workspace_service'
import site_service from '@/services/site_service'

export default {
	name: 'site.show',

	components: {
		layout,
		main_nav,
		support_link,
		site_nav,
		loader,
		board_modal,
		share_modal
	},

	data() {
		return {
			workspace_loading: true,
			site_loading: true,
			site: null,
			workspace: null,
			error: {},
		}
	},

	watch: {
		
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'locales', ['store_locales'] ),

		is_planning_complete() {

			if ( this.site ) {
				return this.site.settings['is_planning_complete']
			}

			return false
		},

		is_design_complete() {

			if ( this.site ) {
				return this.site.settings['is_design_complete']
			}

			return false
		},

		is_build_complete() {

			if ( this.site ) {
				return this.site.settings['is_build_complete']
			}

			return false
		},

		is_finalise_complete() {

			if ( this.site ) {
				return this.site.settings['is_finalise_complete']
			}

			return false
		},

		is_launch_complete() {

			if ( this.site ) {
				return this.site.settings['is_launch_complete']
			}

			return false
		},

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/storage/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

	},

	mounted()
	{
		this.get_workspace()

		this.get_site()

		// this.redirect()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		get_setting( key )
		{
			var value = ( this.site && this.site.settings && this.site.settings[key] !== null ? this.site.settings[key] : null )

			return ( typeof value != 'undefined' ) ? value : null
		},

		async get_site()
		{
			this.site_loading = true 

			this.reset_error()

			site_service.show( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {
				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.settings = JSON.parse( JSON.stringify( response.data.settings ) )

				if ( !this.site.settings['primary'] ) {
					this.site.settings['primary'] = '#0d6efd'
				}
				if ( !this.site.settings['secondary'] ) {
					this.site.settings['secondary'] = '#6c757d'
				}
				if ( !this.site.settings['dark'] ) {
					this.site.settings['dark'] = '#212529'
				}
				if ( !this.site.settings['light'] ) {
					this.site.settings['light'] = '#f8f9fa'
				}
				if ( !this.site.settings['success'] ) {
					this.site.settings['success'] = '#198754'
				}
				if ( !this.site.settings['danger'] ) {
					this.site.settings['danger'] = '#dc3545'
				}
				if ( !this.site.settings['warning'] ) {
					this.site.settings['warning'] = '#ffc107'
				}
				if ( !this.site.settings['info'] ) {
					this.site.settings['info'] = '#0dcaf0'
				}
				
				/*if ( !this.site.settings['brief_complete'] && !this.site.settings['skip_wizard_complete'] ) {
					this.$router.push( { name: 'site.add', params: { site_id: this.site.id } } )
				} else*/ 
				if ( !this.site.is_built ) {
					setTimeout(() => {
						this.get_site()
					}, 30000 )
				}
			}).catch( ( error ) => {
				this.error = error

				this.site_loading = false
			})
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		ucfirst( string )
		{
			return string.charAt(0).toUpperCase() + string.slice(1)
		},

		redirect()
		{
			if ( this.$route.name == 'site.show' ) {
				var route = 'site.planning'

				if ( this.is_details_complete ) {
					route = 'site.design'
				}

				if ( this.is_design_complete ) {
					route = 'site.build'
				}

				/*if ( this.is_build_complete ) {
					route = 'site.launch'
				}*/

				this.$router.push( { name: route } )
			}
			
		}
	}
}
</script>

<style>

</style>
