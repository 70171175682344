<template>
	
	<btn_link 
		label="Email support" 
		route="mailto:support@areoi.io" 
		icon="email" 
	/>

</template>

<script>
import btn_link from '@/components/btn_link'

export default {
	name: 'components.main_nav',

	props: {
		
	},
	components: {
		btn_link
	}
}
</script>

<style>

</style>
