<template>
	
	<div class="modal fade" id="board-modal" tabindex="-1" aria-labelledby="board-modalLabel" aria-hidden="true">

		<div class="modal-dialog modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="board-modalLabel">Planning board</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-if="!active_card" class="modal-body p-3 px-2">

					<div v-if="!loading && board && board.lists" class="w-100 h-100 d-flex flex-grow-1 overflow-hidden">

						<div v-for="(list, list_key) in board.lists" :key="'list-' + list_key" class="bg-light h-100 overflow-auto border rounded mx-2" style="width: 20%;">
							<p class="h5 mb-3 px-2 pt-3">{{ list.name }}</p>
							<div class="p-2">
								<div v-for="(card, card_key) in list.rows" :key="'list-' + list_key + '-card-' + card_key" class="card mb-2 position-relative" :class="card.is_complete ? 'bg-success bg-opacity-25 border-success' : ''">
									<div class="card-body p-3">

										<div v-if="card.due_date_clean" class="mb-2">
											<span class="badge" :class="card.is_complete ? 'bg-success' : 'bg-primary'">Due: {{ card.due_date_clean }}</span>
										</div>

										<p class="mb-0">{{ card.name }}</p>
										<p class="small opacity-50 mb-0">{{ card.is_complete ? 100 : card.percentage }}% of tasks complete</p>

										<div v-if="card.members.length" class="mt-4">
											<span v-for="user in card.members" :key="'list-' + list_key + '-card-' + card_key + '-assigned-' + user.id" class="badge me-2 mb-1" :class="card.is_complete ? 'bg-success' : 'bg-info'">{{ user.email }}</span>
										</div>
									</div>
									<a :href="'#list-' + list_key + '-card-' + card_key + '-modal'" class="stretched-link" data-bs-toggle="modal" :data-bs-target="'#list-' + list_key + '-card-' + card_key + '-modal'"></a>
								</div>
							</div>
						</div>

					</div>

					<div v-else class="h-100 d-flex flex-grow-1 align-items-center justify-content-center">
						<span class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</span>
					</div>

				</div>
			</div>
		</div>
	</div>

	<template v-if="board && board.lists">
		<template v-for="(list, list_key) in board.lists" :key="'list-' + list_key + '-modal'">
			<div v-for="(card, card_key) in list.rows" :key="'list-' + list_key + '-card-' + card_key + '-modal'" class="modal fade board-card-modal" :id="'list-' + list_key + '-card-' + card_key + '-modal'" tabindex="-1" :aria-labelledby="'card-plan-modal-label-' + card_key" aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content position-relative">

						<div v-if="card_loading" class="bg-white bg-opacity-50 position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" style="z-index: 1;">
							<span class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</span>
						</div>

						<div class="modal-header border-0 align-items-start">
							<div class="modal-title">
								<h5 class="mb-2" :id="'card-plan-modal-label-' + card_key">{{ card.name }}</h5>
								<p class="mb-0">in list Plan</p>
							</div>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-12 col-lg-9">

									<div v-if="card.is_complete" class="alert alert-success d-flex small">
										<span class="material-icons-outlined me-2">check</span>
										<div>
											<p class="fw-bold mb-0">Approved</p>
											<p class="mb-0">Marked as complete by {{ card.is_complete_by.email }} on {{ card.is_complete_clean }}</p>
										</div>
									</div>

									<div v-else class="alert alert-info d-flex small">
										<span class="material-icons-outlined me-2">info</span>
										<div>
											<p class="fw-bold mb-0">Not approved</p>
											<p class="mb-0">This card will stay in the "{{ list.name }}" list until it's been approved by the client or a member of the team marks it as complete.</p>
										</div>
									</div>

									<div v-if="card.due_date_clean" class="mb-3">
										<span class="badge bg-primary me-2 mb-2">Due date {{ card.due_date_clean }}</span>
									</div>

									<div v-if="card.members.length" class="mb-3">
										<p class="fw-bold mb-3">Assigned</p>
										<span v-for="user in card.members" :key="'list-' + list_key + '-card-' + card_key + '-modal-assigned-' + user.id" class="badge bg-info me-2 mb-2">{{ user.email }}</span>
									</div>

									<p class="fw-bold mb-3">Description</p>
									<p>{{ card.description }}</p>

									<router-link v-if="card.route" :to="{ name : card.route, params: card.params }" class="btn btn-outline-primary mb-4 d-inline-flex align-items-center" target="_blank">
										{{ card.route_label }}
										<span class="material-icons-outlined ms-2">open_in_new</span>
									</router-link>

									<div v-for="(checklist, checklist_key) in card.checklists" :key="'#list-' + list_key + '-card-' + card_key + '-modal-' + '-checklist-' + checklist_key" class="mb-4">
										<p class="fw-bold mb-3">{{ checklist.name }}</p>

										<div v-if="checklist.percentage" class="progress mb-4">
											<div class="progress-bar" :class="checklist.percentage < 100 ? 'bg-info' : 'bg-success'" :style="'width: ' + checklist.percentage + '%;'">{{ checklist.percentage }}%</div>
										</div>

										<div v-for="(row, row_key) in checklist.rows" :key="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key" class="d-flex align-items-start mb-2">
											<span class="material-icons-outlined me-3 position-relative" :class="row.description && row.is_complete ? 'text-warning' : ''" style="top: 3px;">{{ row.is_complete ? ( row.description ? 'report_problem' : 'check_box') : 'check_box_outline_blank' }}</span>
											<div>
												<p class="mb-0" :class="row.is_complete ? 'text-decoration-line-through' : ''" v-html="row.name"></p>
												<p v-if="row.description" class="form-text mb-0">{{ row.description }}</p>

												<div v-if="row.diff && row.diff.length">
													<button class="btn btn-link px-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" aria-expanded="false" :aria-controls="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'">
														Show / hide differences
													</button>
													<div :id="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" class="w-100 collapse mt-2 small">
														<div class="w-100 overflow-auto border rounded " style="max-height: 300px;">
															<table class="table table-hover w-100 mb-0" style="table-layout: fixed;">
																<thead class="position-sticky top-0" style="z-index: 1">
																	<tr>
																		<th class="bg-light" style="width: 45px;"></th>
																		<th class="bg-light w-50">Original Content</th>
																		<th class="bg-light w-50">Design Content</th>
																	</tr>
																</thead>
																<tbody class="small">
																	<tr v-for="(diff, diff_key) in row.diff" :key="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff-' + diff_key">
																		<td class="border-end bg-opacity-25 text-center" :class="diff.has_differences ? 'bg-danger border-danger text-danger' : 'bg-success border-success'">{{ diff.line }}</td>
																		<td class="w-50" :class="diff.has_differences ? 'border-danger' : 'border-success'" v-html="diff.plan"></td>
																		<td class="w-50" :class="diff.has_differences ? 'border-danger' : 'border-success'" v-html="diff.design"></td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div v-if="row.placeholders && row.placeholders.length">
													<button class="btn btn-link px-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" aria-expanded="false" :aria-controls="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'">
														Show / hide placeholder media
													</button>
													<div :id="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" class="w-100 collapse mt-2 small">
														<div class="w-100 overflow-auto border rounded " style="max-height: 300px;">
															<table class="table table-hover w-100 mb-0" style="table-layout: fixed;">
																<thead class="position-sticky top-0" style="z-index: 1">
																	<tr>
																		<th class="bg-light" style="width: 45px;"></th>
																		<th class="bg-light w-25">Block</th>
																		<th class="bg-light w-75">Title</th>
																	</tr>
																</thead>
																<tbody class="small">
																	<tr v-for="(placeholder, placeholder_key) in row.placeholders" :key="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-placeholder-' + placeholder_key">
																		<td class="border-end bg-opacity-25 text-center" :class="placeholder.has_placeholder ? 'bg-danger border-danger text-danger' : 'bg-success border-success'">{{ placeholder.line }}</td>
																		<td class="w-25" :class="placeholder.has_placeholder ? 'border-danger' : 'border-success'">{{ placeholder.block }}</td>
																		<td class="w-75" :class="placeholder.has_placeholder ? 'border-danger' : 'border-success'">{{ placeholder.title }}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div v-if="row.forms && row.forms.length">
													<button class="btn btn-link px-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" aria-expanded="false" :aria-controls="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'">
														Show / hide blocks
													</button>
													<div :id="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-diff'" class="w-100 collapse mt-2 small">
														<div class="w-100 overflow-auto border rounded " style="max-height: 300px;">
															<table class="table table-hover w-100 mb-0" style="table-layout: fixed;">
																<thead class="position-sticky top-0" style="z-index: 1">
																	<tr>
																		<th class="bg-light" style="width: 45px;"></th>
																		<th class="bg-light w-25">Block</th>
																		<th class="bg-light w-75">Title</th>
																	</tr>
																</thead>
																<tbody class="small">
																	<tr v-for="(form, form_key) in row.forms" :key="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-form-' + form_key">
																		<td class="border-end bg-opacity-25 text-center" :class="form.has_form ? 'bg-danger border-danger text-danger' : 'bg-success border-success'">{{ form.line }}</td>
																		<td class="w-25" :class="form.has_form ? 'border-danger' : 'border-success'">{{ form.block }}</td>
																		<td class="w-75" :class="form.has_form ? 'border-danger' : 'border-success'">{{ form.title }}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div v-if="row.details">
													<button class="btn btn-link px-0" type="button" data-bs-toggle="collapse" :data-bs-target="'#card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-details'" aria-expanded="false" :aria-controls="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-details'">
														Show / hide details
													</button>
													<div :id="'card-plan-modal-' + card_key + '-checklist-' + checklist_key + '-row-' + row_key + '-details'" class="w-100 collapse mt-2 small">
														<div class="w-100 overflow-auto border rounded">
															<table class="table table-hover w-100 mb-0" style="table-layout: fixed;">
																<tbody class="small">
																	<tr v-if="row.details['user']">
																		<td class="w-25">User</td>
																		<td class="w-75">{{ row.details['user'] }}</td>
																	</tr>
																	<tr v-if="row.details['clean_date']">
																		<td class="w-25">Date</td>
																		<td class="w-75">{{ row.details['clean_date'] }}</td>
																	</tr>
																	<tr v-if="row.details['device']">
																		<td class="w-25">Device</td>
																		<td class="w-75">{{ row.details['device'] }}</td>
																	</tr>
																	<tr v-if="row.details['width'] > 0">
																		<td class="w-25">Dimensions</td>
																		<td class="w-75">{{ row.details['width'] }}px x {{ row.details['height'] }}px</td>
																	</tr>
																	<tr v-if="row.details['browser']">
																		<td class="w-25">Browser</td>
																		<td class="w-75">{{ row.details['browser'] }}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<p class="fw-bold mb-3">Comments</p>

									<div>
										<textarea type="text" :name="'list-' + list_key + '-card-' + card_key + '-comment'" class="form-control" placeholder="Add comment..." v-model="card.comment"></textarea>
										<button v-if="card.comment" v-on:click="add_comment( card, card_key )" class="btn btn-secondary mt-2">Save</button>
									</div>

									<div v-for="(comment, comment_key) in card.comments" :key="'list-' + list_key + '-card-' + card_key + '-comment-' + comment_key" class="mb-3 mt-3">
										<p class="mb-2"><span class="fw-bold">{{ ( comment.user && comment.user.email ? comment.user.email : 'Unknown' ) }}</span> - {{ comment.created_clean }}</p>
										
										<div v-if="card.edit_comment != comment_key">
											<div class="card mb-2">
												<div class="card-body p-3">
													{{ comment.message }}
												</div>
											</div>
											<div v-if="comment.user && comment.user.id && auth_user.id == comment.user.id">
												<button v-on:click="[card.new_message = comment.message, card.edit_comment = comment_key]" class="btn btn-link p-0 me-3">Edit</button>
												<button v-on:click="[delete_comment( card, card_key, comment_key )]" class="btn btn-link p-0 me-3">Delete</button>
											</div>
										</div>

										<div v-else>
											<textarea type="text" :name="'list-' + list_key + '-card-' + card_key + '-new-comment'" class="form-control" placeholder="Add comment..." v-model="card.new_message"></textarea>
											<button v-if="card.new_message" v-on:click="update_comment( card, card_key )" class="btn btn-secondary mt-2 me-2">Save</button>
											<button v-on:click="card.edit_comment = null" class="btn btn-outline-secondary mt-2">Cancel</button>
										</div>
									</div>
								</div>
								<div class="col-12 col-lg-3">
									<button v-if="!card.is_complete" v-on:click="mark_complete( card, card_key )" class="btn btn-outline-primary mb-3 w-100 d-flex justify-content-between">
										Mark complete
										<span class="material-icons-outlined">check</span>
									</button>
									<button v-else v-on:click="mark_incomplete( card, card_key )" class="btn btn-outline-primary mb-3 w-100 d-flex justify-content-between">
										Mark incomplete
										<span class="material-icons-outlined">close</span>
									</button>

									<div class="dropdown">
										<button class="btn btn-outline-primary mb-3 w-100 d-flex justify-content-between" type="button" :id="'list-' + list_key + '-card-' + card_key + '-modal-members'" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
											Due date
										<span class="material-icons-outlined">today</span>
										</button>
										<div class="dropdown-menu" style="padding: 2px 0;" :aria-labelledby="'list-' + list_key + '-card-' + card_key + '-modal-members'">
											<flat-pickr v-model="card.due_date" :config="date_config" />

											<div class="p-3">
												<button v-on:click="change_due_date( card, card_key )" class="btn btn-secondary w-100">Save</button>

												<button v-if="card.due_date" v-on:click="clear_due_date( card, card_key )" class="btn btn-outline-secondary w-100 mt-3">Clear</button>
											</div>
										</div>
									</div>

									<div class="dropdown">
										<button class="btn btn-outline-primary mb-3 w-100 d-flex justify-content-between" type="button" :id="'list-' + list_key + '-card-' + card_key + '-modal-members'" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
											Assign to...
											<span class="material-icons-outlined">people</span>
										</button>
										<ul class="dropdown-menu" :aria-labelledby="'list-' + list_key + '-card-' + card_key + '-modal-members'">
											<li v-for="(user) in board.users" :key="'list-' + list_key + '-card-' + card_key + '-modal-members-' + user.id">
												<button v-on:click="manage_members( card, card_key, user )" class="dropdown-item d-flex align-items-center justify-content-between">
													{{ user.email }}
													<span v-if="check_member( card, user )" class="material-icons-outlined ms-2">check</span>
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</template>

</template>

<script>
import { mapGetters } from 'vuex';
import site_service from '@/services/site_service'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
	name: 'components.board_modal',
	props: {
		modelValue: [String, Number, Object, Boolean, Array]
	},
	emits: [
		'update:modelValue',
		'success'
	],
	data() {
		return {
			loading: false,
			card_loading: false,
			populating: false,
			site: null,
			success: null,
			error: {},
			board: false,
			active_card: null,
			date: [],
			date_config: {
				inline: true,
				// enableTime: true,
				// time_24hr: true,
				// enableSeconds: true,
				dateFormat: 'Y-m-d H:i:S',
			}
		}
	},
	components: {
		flatPickr
	},
	watch: {
		'modelValue': function()
		{
			// this.get_board()
		}
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

	},
	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		var $ = window.$ 

		var vm = this

		$( document ).on('hidden.bs.modal', '.board-card-modal', function () {
			$('#board-modal').modal('show');
		});

		$( document ).on('show.bs.modal', '#board-modal', function () {
			vm.get_board();
		});
	},
	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_board()
		{
			this.loading = true 

			this.reset_error()

			site_service.get_board( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.board = JSON.parse( JSON.stringify( response.data ) )

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		mark_complete( card, key )
		{
			card.is_complete = true

			card.is_complete_by = this.auth_user.id

			window.$('.modal').modal('hide');
			
			this.update_status( card, key );
		},

		mark_incomplete( card, key )
		{
			card.is_complete = false

			card.is_complete_by = null
			
			window.$('.modal').modal('hide');

			this.update_status( card, key );
		},

		manage_members( card, key, user )
		{
			var exists = card.members.find(item => item.id === user.id);
			
			if ( exists ) {
				card.members = card.members.filter(item => item.id !== user.id);
			} else {
				card.members.push(user);
			}

			this.update_status( card, key );
		},

		change_due_date( card, key )
		{
			window.$('.dropdown > button').dropdown('hide');

			this.update_status( card, key );
		},

		clear_due_date( card, key )
		{
			card.due_date = ''

			window.$('.dropdown > button').dropdown('hide');

			this.update_status( card, key );
		},

		check_member( card, user )
		{
			if ( !card.members ) {
				return false;
			}
			return card.members.find(item => item.id === user.id);
		},

		add_comment( card, key )
		{
			this.update_status( card, key );
		},

		update_comment( card, key )
		{
			this.update_status( card, key );
		},

		delete_comment( card, key, comment_key )
		{
			card.delete_comment = comment_key

			this.update_status( card, key );
		},

		update_status( card, key )
		{
			this.loading = true 

			this.card_loading = true

			this.reset_error()

			site_service.update_board_status( this.$route.params.workspace_id, this.$route.params.site_id, {
				card: card,
				key: key
			} ).then(( response ) => {

				this.board = JSON.parse( JSON.stringify( response.data ) )

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

				this.card_loading = false

			})
		}
	}
}
</script>

<style>

</style>
