<template>
	
	<nav class="w-100 d-flex flex-column justify-content-center v-step-site-nav">

		<router-link 
			class="nav-btn" 
			:to="{ name: 'workspaces' }" 
			data-bs-toggle="tooltip" data-bs-placement="right" title="All sites" 
		>
			<span class="material-icons-outlined">west</span>		
		</router-link>

		<router-link 
			class="nav-btn v-step-plan"
			v-if="!site.error_message"
			:to="{ name: 'site.planning' }" 
			data-bs-toggle="tooltip" data-bs-placement="right" title="Plan"
		>
			<span class="material-icons-outlined">sort</span>		
		</router-link>

		<router-link 
			class="nav-btn v-step-design"
			v-if="!site.error_message && site.is_built"
			:to="{ name: ( site.settings['design_complete'] || !site.settings['is_wizard'] || site.settings['skip_design_wizard_complete'] ? 'site.design.prototype' : 'site.design' ) }" 
			:class="( $route.name.includes( 'site.design' ) ? 'router-link-active' : '' )"
			data-bs-toggle="tooltip" data-bs-placement="right" title="Design"
		>
			<span class="material-icons-outlined">design_services</span>		
		</router-link>
		<a 
			class="nav-btn opacity-50 v-step-design"
			v-else
			data-bs-toggle="tooltip" data-bs-placement="right" title="Design will become available once site setup is complete"
		>
			<span class="material-icons-outlined">design_services</span>		
		</a>

		<router-link 
			class="nav-btn v-step-build"
			v-if="!site.error_message && site.is_built && (site.settings['design_complete'] || site.settings['skip_design_wizard_complete'] || site.settings['is-design-setup'] || site.settings['design-setup'])"
			:to="{ name: 'site.build' }" 
			data-bs-toggle="tooltip" data-bs-placement="right" title="Build"
		>
			<span class="material-icons-outlined">code</span>		
		</router-link>
		<a 
			class="nav-btn opacity-50 v-step-build"
			v-else
			data-bs-toggle="tooltip" data-bs-placement="right" title="Build"
		>
			<span class="material-icons-outlined">code</span>		
		</a>

		<router-link 
			class="nav-btn v-step-launch"
			v-if="site.is_built && !site.error_message && auth_user.type != 'viewer' && (site.settings['build_complete'] || site.settings['is_build_complete'])"
			:to="{ name: 'site.launch' }" 
			data-bs-toggle="tooltip" data-bs-placement="right" title="Launch"
		>
			<span class="material-icons-outlined">rocket_launch</span>		
		</router-link>
		<a 
			class="nav-btn opacity-50 v-step-launch"
			v-else
			data-bs-toggle="tooltip" data-bs-placement="right" title="Launch"
		>
			<span class="material-icons-outlined">rocket_launch</span>		
		</a>

		<router-link 
			v-if="site.is_built && auth_user.type == 'admin'"
			class="nav-btn text-danger v-step-delete"
			:to="{ name: 'site.destroy' }" 
			data-bs-toggle="tooltip" data-bs-placement="right" title="Delete"
		>
			<span class="material-icons-outlined">cancel</span>		
		</router-link>
		<a 
			class="nav-btn text-danger opacity-50 v-step-delete"
			v-else
			data-bs-toggle="tooltip" data-bs-placement="right" title="Delete"
		>
			<span class="material-icons-outlined">cancel</span>		
		</a>
	</nav>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'components.planning_nav',

	props: {
		site: Object,
		preview: String,
		is_planning_complete: Boolean,
		is_design_complete: Boolean,
		is_build_complete: Boolean,
		is_finalise_complete: Boolean,
		is_launch_complete: Boolean
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	components: {
		
	},

	methods: {
		btn_icon( step, icon )
		{
			return icon
			// return ( this[step] ? 'check_circle' : icon );

		},
	}
}
</script>

<style>

</style>
