import axios from 'axios'

export const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async create_intent( payload ) {

		return client.post( '/api/billing/create-intent', payload )

	},

	async add_payment_method( payload ) {

		return client.post( '/api/billing/add-payment-method', payload )

	},

	async billing_portal_url( payload ) {

		return client.post( '/api/billing/portal-url', payload )

	},

}