<template>
	
	<div class="h-100 w-100 d-flex h-100" style="min-width: 1180px;">

		<header v-if="include_header" class="header h-100 bg-white border-end py-3 d-flex flex-column" style="flex: 0 0 64px;">

			<img src="@/assets/img/Icon_Dark.svg" height="30" class="d-block mx-auto">

			<div v-if="$slots['header-center']" class="flex-grow-1 w-100 h-100 d-flex align-items-center">
				<slot name="header-center"></slot>
			</div>

			<div style="height: 30px;"></div>

		</header>

		<div v-if="$slots['actions']" class="header border-bottom py-3 bg-light">

			<div class="container-fluid">

				<div class="row align-items-center">

					<div class="col">
						<slot name="actions"></slot>
					</div>

				</div>

			</div>

		</div>

		<section class="main flex-grow-1 d-flex bg-light overflow-hidden">
				
			<div v-if="$slots['main-sidebar']" class="main-sidebar h-100 border-end">
				<slot name="main-sidebar"></slot>
			</div>
			
			<slot v-if="$slots['main-body']" name="main-body"></slot>

		</section>

	</div>

</template>

<script>
export default {
	name: 'components.layout',
	props: {
		include_header: Boolean
	}
}
</script>

<style>
.main-sidebar {
	width: 450px;
}
</style>
