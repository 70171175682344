<template>
	
	<a :href="route" class="d-inline-flex align-items-center" :class="( !btn_class ? 'btn btn-outline-primary' : btn_class )" :target="( is_blank ? '_blank' : '' )">
		
		<span v-if="!icon_after" class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
			<span v-if="!loading" class="material-icons-outlined me-2">{{ ( icon ? icon : 'east' ) }}</span>

			<span v-else class="d-flex align-items-center justify-content-center">
				<span class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
			</span>
		</span>

		{{ label }}

		<span v-if="icon_after" class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
			<span v-if="!loading" class="material-icons-outlined ms-2">{{ ( icon ? icon : 'east' ) }}</span>

			<span v-else class="d-flex align-items-center justify-content-center">
				<span class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
			</span>
		</span>

	</a>

</template>

<script>
export default {
	name: 'components.btn_router_link',
	props: {
		route: String,
		label: String,
		icon: String,
		icon_after: Boolean,
		btn_class: String,
		is_blank: Boolean,
		loading: Boolean
	}
}
</script>

<style>

</style>
